import React, { type FunctionComponent } from 'react';

export type DoorMarkerProps = {
	color?: 'accent' | 'white';
};

/**
 * This is the stylized 'i' in build.com that looks like a door.  The intent is
 * that this component be used as an indentation marker within promotions
 *
 * TODO: evaluate if this is used elsewhere and should be moved out of promo-helpers
 */
export const DoorMarker: FunctionComponent<DoorMarkerProps> = ({ color = 'accent' }) => (
	<span
		className="b--solid f5 mr2"
		style={{
			borderWidth: '3px 5px 3px 0',
			borderColor: `transparent ${color === 'white' ? '#fff' : '#C25500'} transparent transparent`
		}}>
		{' '}
	</span>
);
